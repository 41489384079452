import { useTitleContext } from "../../contexts/TitleContext";
import { useEffect, useState } from "react";
import NewBreakdown from "../../components/SalesOrders/NewBreakdown";
import { LinearProgress } from "@mui/material";

export default function NewOrder() {
  const { setTitle } = useTitleContext();
  const [progressVisible, setProgressVisible] = useState(false);

  useEffect(() => {
    setProgressVisible(true);
    setTitle("New Order");
    setProgressVisible(false);
  }, [setTitle]);

  return (
    <>
      {progressVisible && <LinearProgress />}
      <NewBreakdown />
    </>
  );
}
