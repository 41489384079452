import { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useAuthContext } from "src/auth/useAuthContext";
import { IBreakdown } from "../@types/breakdown";
import SalesOrdersService from "../services/SalesOrdersService";
import Progress from "../components/progress/Progress";
import BreakdownCard from "../components/SalesOrders/BreakdownCard";
import { useTitleContext } from "../contexts/TitleContext";
import { BUTTONBAR } from "../config-global";

export default function SalesOrders() {
  const { setTitle } = useTitleContext();
  const { isAuthenticated, user } = useAuthContext();
  const [progressVisible, setProgressVisible] = useState(false);
  const [filteredResults, setFilteredResults] = useState<IBreakdown[] | null>(
    null
  );
  const [breakdownsResults, setBreakdownsResults] = useState<
    IBreakdown[] | null
  >(null);
  const [filterOrdersOnly, setFilterOrdersOnly] = useState(false);
  const [timer, setTimer] = useState(30);
  const [showMTS, setShowMTS] = useState(true);
  const [showTAB, setShowTAB] = useState(true);
  const [showMTSC, setShowMTSC] = useState(true);
  const [permissionToSeeMTS, setPermissionToSeeMTS] = useState(false);
  const [permissionToSeeTAB, setPermissionToSeeTAB] = useState(false);
    const [permissionToSeeMTSC, setPermissionToSeeMTSC] = useState(false);
  const [fitterName, setFitterName] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setProgressVisible(true);
    setTitle("Sales Orders");
    if (isAuthenticated) {
      const name = user?.surname + " " + user?.givenName;
      setFitterName(name);
      (async function loadDiagnosticData() {
        const response = await SalesOrdersService.getPagedBreakdowns();
        if (response) {
          setBreakdownsResults(response);
          setFilteredResults(response);
        }
        setProgressVisible(false);
      })();
    }
  }, [isAuthenticated, setTitle, user]);

  const handleRefreshList = useCallback(async () => {
    setProgressVisible(true);
    const response = await SalesOrdersService.getPagedBreakdowns();
    if (response) {
      setBreakdownsResults(response);
      setFilteredResults(
        response.filter(
          (breakdown) =>
            (!filterOrdersOnly || breakdown.fitter === fitterName) &&
            (
                (showMTS && breakdown.company === "MTS") ||
              (showTAB && breakdown.company === "TAB") ||
                (showMTSC && breakdown.company === "MTSC")
            )
        )
      );
    }
    setProgressVisible(false);
  }, [filterOrdersOnly, fitterName, showMTS, showTAB, showMTSC]);

  useEffect(() => {
    const getPermissions = async () => {
      const permissions = await SalesOrdersService.getPermissions();
      if (permissions) {
        setPermissionToSeeMTS(permissions.mts);
        setPermissionToSeeTAB(permissions.tab);
        setPermissionToSeeMTSC(permissions.mtsc);
        //setShowMTS(permissions.mts);
        //setShowTAB(permissions.tab);
      }
    };

    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          handleRefreshList();
          getPermissions();
          return 30;
        }
        return prevTimer - 1;
      });
    }, 1000);
    getPermissions();
    return () => clearInterval(interval);
  }, [handleRefreshList]);

  const handleFilterOrdersOnly = () => {
    console.log("Current filter status in handle function:", filterOrdersOnly);
    setFilterOrdersOnly(!filterOrdersOnly);
    if (!filterOrdersOnly) {
      console.log("Filtering by:", fitterName);
      setFilteredResults(
        breakdownsResults?.filter(
          (breakdown) =>
            breakdown.fitter === fitterName &&
            (
                (showMTS && breakdown.company === "MTS") ||
              (showTAB && breakdown.company === "TAB") ||
                (showMTSC && breakdown.company === "MTSC")
            )
        ) || null
      );
    } else {
      setFilteredResults(
        breakdownsResults?.filter(
          (breakdown) =>
            (showMTS && breakdown.company === "MTS") ||
            (showTAB && breakdown.company === "TAB") ||
            (showMTSC && breakdown.company === "MTSC")
            
        ) || null
      );
    }
  };
  const handleShowMTS = () => {
    const newShowMTS = !showMTS; // Toggle the value of showMTS
    setShowMTS(newShowMTS); // Update the state

    // Use the updated value for filtering
    setFilteredResults(
      breakdownsResults?.filter(
        (breakdown) =>
          ((newShowMTS && breakdown.company === "MTS") ||
            (showTAB && breakdown.company === "TAB")) &&
          (!filterOrdersOnly || breakdown.fitter === fitterName)
      ) || null
    );
  };

  const handleShowTAB = () => {
    const newShowTAB = !showTAB; // Toggle the value of showTAB
    setShowTAB(newShowTAB); // Update the state

    // Use the updated value for filtering
    setFilteredResults(
      breakdownsResults?.filter(
        (breakdown) =>
          ((showMTS && breakdown.company === "MTS") ||
            (newShowTAB && breakdown.company === "TAB")) &&
          (!filterOrdersOnly || breakdown.fitter === fitterName)
      ) || null
    );
  };
  
    const handleShowMTSC = () => {
        const newShowMTSC = !showMTSC; // Toggle the value of showTAB
        setShowMTSC(newShowMTSC); // Update the state
    
        // Use the updated value for filtering
        setFilteredResults(
        breakdownsResults?.filter(
            (breakdown) =>
            ((showMTS && breakdown.company === "MTS") ||
                (showTAB && breakdown.company === "TAB") ||
                (newShowMTSC && breakdown.company === "MTSC")) &&
            (!filterOrdersOnly || breakdown.fitter === fitterName)
        ) || null
        );
    };

  const handleAddNewElement = () => {
    window.location.href = "/salesorders/neworder";
  };

  return (
    <Container>
      <Progress visible={progressVisible} sx={{ mt: 10 }} />
      <Helmet>
        <title>Sales Orders</title>
      </Helmet>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: "fixed",
          top: 70,
          left: BUTTONBAR.H_MOBILE_LEFT, //isMobile ? BUTTONBAR.H_MOBILE_LEFT : BUTTONBAR.H_DESKTOP_LEFT,
          width: "100%", //!isMobile ? `calc(100% - ${BUTTONBAR.H_DESKTOP_LEFT}px)` : '100%',
          height: isMobile ? 56 : "auto",
          backgroundColor: "white",
          zIndex: 1000, // Ensures it's on top of other elements
          padding: "16px 16px",
          boxShadow: "0px 4px 2px -2px gray", // Adds shadow for better visibility
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "black",
                },
              }}
              checked={filterOrdersOnly}
              onChange={handleFilterOrdersOnly}
            />
          }
          label="My Orders"
        />
        {permissionToSeeMTS && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "black",
                  "&.Mui-checked": {
                    color: "black",
                  },
                }}
                checked={showMTS}
                onChange={handleShowMTS}
              />
            }
            label="MTS"
          />
        )}
        {permissionToSeeTAB && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "black",
                  "&.Mui-checked": {
                    color: "black",
                  },
                }}
                checked={showTAB}
                onChange={handleShowTAB}
              />
            }
            label="TAB"
          />
        )}
        {permissionToSeeMTSC && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "black",
                  "&.Mui-checked": {
                    color: "black",
                  },
                }}
                checked={showMTSC}
                onChange={handleShowMTSC}
              />
            }
            label="MTSC"
          />
        )}
        <Box display="flex" alignItems="center" flexDirection="column">
          <IconButton
            onClick={handleRefreshList}
            aria-label="refresh"
            sx={{
              color: "white",
              backgroundColor: "black",
              "&:active": { backgroundColor: "black" },
            }}
          >
            <RefreshIcon />
          </IconButton>
          <Typography variant="body2" sx={{ color: "black", fontSize: "9px" }}>
            {timer}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ marginLeft: 1 }}
        >
          <IconButton
            onClick={handleAddNewElement}
            aria-label="refresh"
            sx={{
              color: "white",
              backgroundColor: "red",
              "&:active": { backgroundColor: "black" },
            }}
          >
            <AddIcon />
          </IconButton>
          <Typography variant="body2" sx={{ color: "black", fontSize: "9px" }}>
            New
          </Typography>
        </Box>
      </Box>

      {/*<Box sx={{ marginTop: isMobile ? '63px' : '75px' }}> /!* Adds margin to prevent content overlap *!/*/}
      {/*    <Progress visible={progressVisible} />*/}
      {/*    {filteredResults?.map((breakdown) => (*/}
      {/*        <BreakdownCard key={breakdown.id} isExpanded={false} breakdown={breakdown} />*/}
      {/*    ))}*/}
      {/*</Box>*/}

      <Box sx={{ marginTop: "95px" }}>
        {" "}
        {/* Adds margin to prevent content overlap */}
        <Progress visible={progressVisible} />
        {filteredResults?.map((breakdown) => (
          <BreakdownCard
            key={breakdown.id}
            isExpanded={false}
            breakdown={breakdown}
          />
        ))}
      </Box>
    </Container>
  );
}
