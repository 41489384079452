import http from "axios";
import { IConfig } from "../@types/config";

export const getConfig = async (): Promise<IConfig> => {
  try {
    const url = `/configuration/`;
    const { data } = await http.get(url);

    return data;
  } catch (error) {
    console.error(error);
    return null as never;
  }
};

const ConfigService = {
  getConfig,
};

export default ConfigService;
