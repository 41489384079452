import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

export default function Progress(props: any) {
  const { visible } = props;
  return (
    <Box sx={{ width: "100%", top: "50px" }}>
      {visible && <LinearProgress color={"warning"} />}
    </Box>
  );
}
