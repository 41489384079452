// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTSROOTS_AUTH = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, '/'),
  salesorders: path(ROOTS_DASHBOARD, "salesorders"),
  shipments: path(ROOTS_DASHBOARD, "shipments"),
  transfers: path(ROOTS_DASHBOARD, "transfers"),
  notifications: path(ROOTS_DASHBOARD, "notifications"),
  admin: path(ROOTS_DASHBOARD, "admin"),
  diagnostics: path(ROOTS_DASHBOARD, "diagnostics"),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
  },
};

