import { openDB, IDBPDatabase } from "idb";
import { IStock } from "../@types/stock";

const DB_NAME = "fitterDB";
const STORE_NAME = "fitter";

async function getDB(): Promise<IDBPDatabase> {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const store = db.createObjectStore(STORE_NAME, { keyPath: "id" });
        store.createIndex("company", "company", { unique: false });
      }
    },
  });
}

export async function saveStocks(stocks: IStock[]): Promise<void> {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  const store = tx.objectStore(STORE_NAME);
  for (const stock of stocks) {
    store.put(stock);
  }
  await tx.done;
}
export async function getStocksByCompany(company: string): Promise<IStock[]> {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, "readonly");
  const store = tx.objectStore(STORE_NAME);
  const index = store.index("company");
  const result = await index.getAll(company);
  return result;
}

export async function getStocks(): Promise<IStock[]> {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, "readonly");
  const store = tx.objectStore(STORE_NAME);
  return await store.getAll();
}

export async function clearStocks(): Promise<void> {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  const store = tx.objectStore(STORE_NAME);
  await store.clear();
}
