import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
    Box,
    Container,
    Typography,
    Tabs,
    Tab,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    Grid,
    Autocomplete, IconButton,
} from "@mui/material";
import TransfersService from "../services/TransfersService";
import { useAuthContext } from "src/auth/useAuthContext";
import { useTitleContext } from "../contexts/TitleContext";
import Progress from "../components/progress/Progress";
import { BUTTONBAR } from "../config-global";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IStock, IWarehouse, TransferLine } from "../@types/stock";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Transfers() {
    const { setTitle } = useTitleContext();
    const { isAuthenticated, user } = useAuthContext();
    const [progressVisible, setProgressVisible] = useState(false);
    const [fitterName, setFitterName] = useState("");
    const [van, setVan] = useState<string>("");
    const [currentStock, setCurrentStock] = useState([]);
    const [sources, setSources] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [products, setProducts] = useState<IStock[]>([]);
    const [selectedSource, setSelectedSource] = useState("");
    const [selectedDestination, setSelectedDestination] = useState("");
    const [newItemDialogOpen, setNewItemDialogOpen] = useState(false);
    const [newItem, setNewItem] = useState({
        product: "",
        itemNo: "",
        description: "",
        quantity: 0,
        comment: "",
    });
    const [tabIndex, setTabIndex] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [isPendingTransfer, setIsPendingTransfer] = useState(false);
    const [destinationInputValue, setDestinationInputValue] = useState("");
    const [currentItemsTransfer, setCurrentItemsTransfer] = useState<TransferLine[]>([]);

    useEffect(() => {
        const savedItems = localStorage.getItem('currentItemsTransfer');
        if (savedItems) {
            setCurrentItemsTransfer(JSON.parse(savedItems));
            setIsPendingTransfer(true);
        }
        const savedSource = localStorage.getItem('selectedSource');
        if (savedSource) {
            setSelectedSource(savedSource);
        }
        const savedDestination = localStorage.getItem('selectedDestination');
        if (savedDestination) {
            setSelectedDestination(savedDestination);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('currentItemsTransfer', JSON.stringify(currentItemsTransfer));
        setIsPendingTransfer(currentItemsTransfer.length > 0);
    }, [currentItemsTransfer]);

    useEffect(() => {
        setProgressVisible(true);
        //setIsPendingTransfer(false);
        setTitle("Transfers");
        
        if (isAuthenticated) {
            const name = user?.surname + ", " + user?.givenName;
            setFitterName(name);
            (async function fetchData() {
                const vanData: string = await TransfersService.GetCurrentVan();
                setVan(vanData);
                const stockData = await TransfersService.GetCurrentStock(vanData);
                setCurrentStock(stockData);
                const sourcesData = await TransfersService.GetSources(fitterName);
                setSources(sourcesData);
                const destinationsData = await TransfersService.GetDestinations(fitterName);
                setDestinations(destinationsData);
                setProgressVisible(false);
            })();
        }
    }, [isAuthenticated, setTitle, user, fitterName]);
    
    useEffect(() => {
        setTabIndex(isPendingTransfer ? 1 : 0);
    }, [isPendingTransfer]);
    
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleNewItemDialogOpen = async () => {
        try {
            setProgressVisible(true);
            const productsData = await TransfersService.GetCurrentStock(selectedSource);
            setProducts(productsData || []);
            setNewItemDialogOpen(true);
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setProgressVisible(false);
        }
    };

    const handleNewItemDialogClose = () => {
        setNewItemDialogOpen(false);
    };

    const handleNewItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewItem((prevItem) => ({ ...prevItem, [name]: value }));
    };

    const handleRemoveItem = (itemNo: string) => {
        setCurrentItemsTransfer((prevItems) => prevItems.filter(item => item.itemNo !== itemNo));
    };

    const handleSaveNewItem = () => {
        setCurrentItemsTransfer((prevItems) => [...prevItems, newItem]);
        handleNewItemDialogClose();
    };

    const handleFinishTransfer = async () => {
        const transferObject = {
            source: selectedSource,
            destination: selectedDestination,
            lines: currentItemsTransfer,
        };
        await TransfersService.Create(transferObject);
        // Navigate to Dashboard page
    };
    
    const handleCancelTransfer = () => {
        setCurrentItemsTransfer([]);
        setIsPendingTransfer(false);
        localStorage.setItem('currentItemsTransfer', JSON.stringify(currentItemsTransfer));
        setSelectedSource("");
        localStorage.setItem('selectedSource', "");
        setSelectedDestination("");
        localStorage.setItem('selectedDestination', "");
    }
    
    return (
        <Container>
            <Progress visible={progressVisible} sx={{ mt: 10 }} />
            <Helmet>
                <title>Transfers | Fitter Global</title>
            </Helmet>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    position: "fixed",
                    top: 70,
                    left: BUTTONBAR.H_MOBILE_LEFT,
                    width: "100%",
                    height: isMobile ? 56 : "auto",
                    backgroundColor: "white",
                    zIndex: 1000,
                    padding: "16px 16px",
                    boxShadow: "0px 4px 2px -2px gray",
                }}
            >
                {isPendingTransfer && (
                    <>
                        <Button variant="contained"
                                onClick={handleCancelTransfer}
                        sx={{ backgroundColor: "white", color: "red" }}        
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "black", color: "white" }}
                            onClick={handleFinishTransfer}
                        >
                            Finish Transfer
                        </Button>
                    </>
                )}
            </Box>

            <Box
                sx={{
                    mb: 2,
                    mt: 8,
                    backgroundColor: "white",
                    border: 1,
                    borderRadius: 1,
                    padding: 1,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="gray">
                            Fitter
                        </Typography>
                        <Typography variant="body1" fontWeight={600}>
                            {fitterName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="gray">
                            Van
                        </Typography>
                        <Typography variant="body1" fontWeight={600}>
                            {van}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                sx={{
                    backgroundColor: "white",
                    borderRadius: 0,
                    border: 1,
                    padding: 1,
                    "& .MuiTabs-indicator": {
                        backgroundColor: "black",
                        height: "3px",
                    },
                    "& .MuiTab-root": {
                        color: "black",
                        "&.Mui-selected": {
                            color: "black",
                            backgroundColor: "white",
                            fontWeight: "bold",
                            fontSize: "1.25rem",
                            padding: 1,
                            borderBottom: "3px solid black",
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        },
                    },
                }}
            >
                <Tab label="Current VAN Stock" />
                <Tab label="Transfers" />
            </Tabs>

            {tabIndex === 0 && (
                <Box
                    sx={{
                        mt: 0,
                        backgroundColor: "white",
                        border: 1,
                        borderRadius: 0,
                        padding: 1,
                    }}
                >
                    <Grid container spacing={2}>
                        {currentStock.map((stock: IStock) => (
                            <Grid item xs={12} key={stock.itemNo}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderColor: stock.company === "TAB" ? "green" : stock.company === "MTS" ? "black" : "blue",
                                        borderBottom: 1,
                                        padding: 1,
                                        position: "relative",
                                    }}
                                >
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="body2" color="gray">
                                            Item
                                        </Typography>
                                        <Typography variant="body1" fontWeight={600}>
                                            {stock.itemNo}
                                        </Typography>
                                        <Typography variant="body2">{stock.description}</Typography>
                                    </Box>
                                    <Box sx={{ flex: 1, textAlign: "center" }}>
                                        <Typography variant="body2" color="gray">
                                            Quantity
                                        </Typography>
                                        <Typography variant="body1" fontWeight={600}>
                                            {stock.inventory}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ flex: 1, textAlign: "right" }}>
                                        <Typography variant="body2" color="gray">
                                            {stock.company}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            width: "5px",
                                            height: "100%",
                                            backgroundColor:
                                                stock.company === "TAB" ? "green" : stock.company === "MTS" ? "black" : "blue",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}

            {tabIndex === 1 && (
                <Box
                    sx={{
                        mt: 0,
                        backgroundColor: "white",
                        border: 1,
                        borderRadius: 0,
                        padding: 1,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={sources}
                                getOptionLabel={(option: IWarehouse) => option.locationCode || "Unknown"}
                                value={sources.find((source:IWarehouse) => source.locationCode === selectedSource) || null}
                                onChange={(event, newValue) => {
                                    setSelectedSource(newValue ? newValue.locationCode : "");
                                    localStorage.setItem('selectedSource', newValue ? newValue.locationCode : "");
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Source Warehouse"
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            ...params.InputProps,
                                            readOnly: isPendingTransfer,
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.locationCode} - {option.name}
                                    </li>
                                )}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={destinations}
                                getOptionLabel={(option: IWarehouse) => option.locationCode || "Unknown"}
                                value={destinations.find((destination:IWarehouse) => destination.locationCode === selectedDestination) || null}
                                onChange={(event, newValue) => {
                                    setSelectedDestination(newValue ? newValue.locationCode : "");
                                    localStorage.setItem('selectedDestination', newValue ? newValue.locationCode : "");
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Destination Warehouse"
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            ...params.InputProps,
                                            readOnly: isPendingTransfer,
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.locationCode} - {option.name}
                                    </li>
                                )}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 1 }}>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "black", color: "white" }}
                            onClick={handleNewItemDialogOpen}
                        >
                            Add New Item
                        </Button>
                    </Box>

                    {currentItemsTransfer.map((item) => (
                        <Box
                            key={item.itemNo}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderColor: "black",
                                borderBottom: 1,
                                padding: 1,
                                position: "relative",
                            }}
                        >
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="body2" color="gray">
                                    Item
                                </Typography>
                                <Typography variant="body1" fontWeight={600}>
                                    {item.itemNo}
                                </Typography>
                                <Typography variant="body2">{item.description}</Typography>
                            </Box>
                            <Box sx={{ flex: 1, textAlign: "center" }}>
                                <Typography variant="body2" color="gray">
                                    Quantity
                                </Typography>
                                <Typography variant="body1" fontWeight={600}>
                                    {item.quantity}
                                </Typography>
                            </Box>
                            <Box sx={{ flex: 1, textAlign: "right" }}>
                                <IconButton sx={{ color: "red" }}
                                            onClick={() => handleRemoveItem(item.itemNo)}>
                                    <DeleteIcon />
                                </IconButton>

                            </Box>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    width: "5px",
                                    height: "100%",
                                    backgroundColor: "black",
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            )}

            <Dialog open={newItemDialogOpen} onClose={handleNewItemDialogClose}>
                <DialogTitle>Add New Item</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={products}
                        getOptionLabel={(option: IStock) => option.itemNo || "Unknown"}
                        value={products.find((product) => product.itemNo === newItem.product) || null}
                        onChange={(event, newValue: IStock | null) => {
                            setNewItem((prevItem) => ({
                                ...prevItem,
                                product: newValue ? newValue.itemNo : "",
                                itemNo: newValue ? newValue.itemNo : "",
                                description: newValue ? newValue.description : "",
                            }));

                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Product"
                                fullWidth
                                margin="normal"
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                                {option.itemNo} - {option.description}
                            </li>
                        )}
                    />
                    <TextField
                        label="Quantity"
                        name="quantity"
                        type="number"
                        value={newItem.quantity}
                        onChange={(event) => {
                            const value = Math.max(0, Number(event.target.value));
                            setNewItem((prevItem) => ({ ...prevItem, quantity: value }));
                        }}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Comment"
                        name="comment"
                        value={newItem.comment}
                        onChange={handleNewItemChange}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNewItemDialogClose} sx={{color:"black"}}>Cancel</Button>
                    <Button onClick={handleSaveNewItem} sx={{color:"white", backgroundColor:"black"}}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}