import React, {useRef, useState} from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import { BUTTONBAR } from "../../config-global";
import CheckIcon from "@mui/icons-material/Check";
import {
  IShipmentDetails,
  IShipmentDetailsObject,
} from "../../@types/shipment";
import Progress from "../progress/Progress";
import ShipmentsService from "../../services/ShipmentsService";
import SignatureCanvas from "react-signature-canvas";
import SalesOrdersService from "../../services/SalesOrdersService";
import Shipment from "../../redux/slices/shipment";

interface IShipmentDetailProps {
  shipmentObject: IShipmentDetailsObject;
}

export default function ShipmentDetail({
  shipmentObject,
}: IShipmentDetailProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const pendingDelivery = shipmentObject?.status === "UpdatedLoad" || false;
  const [progressVisible, setProgressVisible] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLine, setSelectedLine] = useState<IShipmentDetails | null>(
    null
  );
  const [qtyPicked, setQtyPicked] = useState<string | "0">("0");

  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const sigPad = useRef<SignatureCanvas | null>(null);
  const [deliveryList, setDeliveryList] = useState<Array<IShipmentDetails>>([]);
  const handleCloseSignatureDialog = () => {
    setOpenSignatureDialog(false);
  };
  
    const handleCompleteDelivery = async () => {
        const signature = sigPad.current?.toDataURL("image/png");
        if (signature) {
          const signatureFileName = `${shipmentObject.id}_signature.png`;

          // Convert the base64 signature into a Blob
          const response = await fetch(signature);
          const blob = await response.blob();

          // Convert the Blob into a File
          const file = new File([blob], signatureFileName, { type: "image/png" });

          // Upload the signature image to your images folder
          const sastoken = await SalesOrdersService.generateSasUrl(
              shipmentObject.id,
              signatureFileName
          );
          await SalesOrdersService.uploadFileToBlobStorage(file, sastoken);
        setProgressVisible(true);
        await ShipmentsService.DeliverShipment(
            shipmentObject.id,
            shipmentObject.clientNo,
            signatureFileName
        );
        setProgressVisible(false);
        setOpenSignatureDialog(false);
        }
    };
  const handleDelivery = async () => {
    
    setDeliveryList(shipmentObject.lines);
    setOpenSignatureDialog(true);
    
    setProgressVisible(false);
  };
  const handleCardClick = (line: IShipmentDetails) => {
    if (shipmentObject?.status === "ReadyToPick") {
      setSelectedLine(line);
      setQtyPicked(line.qtyToShip.toString() || "0");
      setOpenDialog(true);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedLine(null);
    setQtyPicked("");
  };

  const handleQtyToShipChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setQtyPicked(event.target.value);
  };

  const handleDialogSave = () => {
    if (selectedLine) {
      selectedLine.qtyToShip = Number(qtyPicked);
      console.log("Updated Quantity:", qtyPicked);
    }
    handleDialogClose();
  };

  return (
    <>
      <Progress visible={progressVisible} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          top: 40,
          // top: 150,
          // position: "fixed",
          // ml: 1,  
          width: "100%", 
          backgroundColor: "black",
            color: "white",
          zIndex: 1000,
         padding: "10px 10px",
          boxShadow: "0px 4px 2px -2px gray",
          mb: 2,
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="body2"
            sx={{ fontSize: "14px", fontWeight: 600 }}
          >
            {shipmentObject?.clientName}
          </Typography>
        </Box>

        {pendingDelivery && (
          <>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton
                onClick={handleDelivery}
                sx={{
                  color: "white",
                  backgroundColor: "green",
                  fontWeight: 800,
                }}
              >
                <CheckIcon />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ color: "red", fontSize: "14px", fontWeight: 600 }}
              >
                Deliver
              </Typography>
            </Box>
          </>
        )}
        {!pendingDelivery && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="body2"
              sx={{
                color: "red",
                fontSize: "14px",
                fontStyle: "italic",
                fontWeight: 600,
              }}
            >
              {shipmentObject?.status ?? "ReadyToPick" ? "Picking" : ""}
            </Typography>
          </Box>
        )}
      </Box>
      
      {shipmentObject.lines.map((line, index) => (
        <Card key={index} sx={{ mt: 2, mb:1 }} onClick={() => handleCardClick(line)}>
          <CardContent>
            <Box display="flex-start" alignItems="center">
              {line?.description}
            </Box>
            <Grid container spacing={0.5} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", color: "gray", marginRight: 1 }}
                >
                  Quantity:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {line?.quantity}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", color: "gray", marginRight: 1 }}
                >
                  Qty To Ship:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {line?.qtyToShip}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{selectedLine?.customerName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box display="flex-start" alignItems="center">
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
              >
                {selectedLine?.description}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "gray", marginRight: 1 }}
              >
                ItemNo:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
              >
                {selectedLine?.itemNo}
              </Typography>
            </Box>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Picked"
            type="number"
            fullWidth
            value={qtyPicked}
            onChange={handleQtyToShipChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{ color: "red" }}>
            Cancel
          </Button>
          <Button onClick={handleDialogSave} sx={{ border: 1, color: "black" }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={openSignatureDialog}
          onClose={handleCloseSignatureDialog}
          maxWidth="sm"
      >
        <DialogTitle>Sign to Accept Delivery</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Please sign below to finish the job:
          </Typography>
          <Box sx={{ border: "1px solid black", mt: 2 }}>
            <SignatureCanvas
                ref={sigPad}
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            {deliveryList.map((line, index) => (
                <Card key={index} sx={{ mt: 16 }} onClick={() => handleCardClick(line)}>
                  <CardContent>
                    <Box display="flex-start" alignItems="center">
                      {line?.description}
                    </Box>
                    <Grid container spacing={0.5} sx={{ mt: 1 }}>
                      <Grid item xs={6}>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: "12px", color: "gray", marginRight: 1 }}
                        >
                          Quantity:
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                        >
                          {line?.quantity}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: "12px", color: "gray", marginRight: 1 }}
                        >
                          Qty To Ship:
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                        >
                          {line?.qtyToShip}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
            ))}
          </Box>
         
        
        </DialogContent>
        <DialogActions>
          <Button
              onClick={handleCloseSignatureDialog}
              sx={{ border: 0, color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={handleCompleteDelivery} sx={{ border: 1, color: "green" }}>
            Complete Delivery
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
