import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Button,
} from "@mui/material";
import CustomersService from "../../services/CustomersService";
import { ICustomer } from "../../@types/customer";
import { useNavigate } from "react-router-dom";
import SalesOrdersService from "../../services/SalesOrdersService";
import { IBreakdownDto } from "../../@types/breakdown";

export default function NewBreakdown() {
  const [company, setCompany] = useState("");
  const borderColor = company === "TAB" ? "darkgreen" : "black";

  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
    null
  );
  const [location, setLocation] = useState("");
  const [vehicleRegistrationNo, setVehicleRegistrationNo] = useState("");
  const [customerComment, setCustomerComment] = useState("");
  const [fitterComment, setFitterComment] = useState("");
  const [mileage, setMileage] = useState(0);
  const [workDescription, setWorkDescription] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      const response = await CustomersService.getCustomers();
      if (response) {
        setCustomers(response);
      }
    };
    fetchCustomers();
  }, []);

  const handleSelectCustomer = (customer: ICustomer | null) => {
    if (customer) {
      setSelectedCustomer(customer);
      setCompany(customer?.company || "");
    } else {
      setSelectedCustomer(null);
      setCompany("");
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    const data: IBreakdownDto = {
      company: company,
      customerNo: selectedCustomer?.no ?? "",
      customerName: selectedCustomer?.name ?? "",
      location: location ?? "",
      vehicleRegistrationNo: vehicleRegistrationNo ?? "",
      workDescription: workDescription ?? "",
      customerComment: customerComment ?? "",
      fitterDescription: fitterComment ?? "",
      mileage: mileage,
    };
    await SalesOrdersService.createOrder(data);
    navigate(-1);
  };

  return (
    <Card
      sx={{
        mt: 1,
        mx: 2,
        borderRadius: "10px",
        border: "1px solid",
        borderColor: borderColor,
        cursor: "pointer",
        position: "relative",
        backgroundColor: "#fbfbfb",
      }}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: "10px", textAlign: "left" }}
          >
            New Breakdown
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "9px",
              textAlign: "right",
              color: company === "TAB" ? "darkgreen" : "black",
            }}
          >
            {company}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body2"
              sx={{ fontSize: "10px", color: "gray", mb: 1 }}
            >
              Customer *
            </Typography>
            <Autocomplete
              options={customers}
              getOptionLabel={(option) => option.name || "Unknown"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    backgroundColor: "white",
                    borderColor: "black",
                    borderRadius: "0px",
                    height: "40px",
                    fontSize: "12px",
                    flexGrow: 1,
                  }}
                  fullWidth
                  InputProps={{ ...params.InputProps, sx: { height: "40px" } }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.id}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{option.name}</span>
                  <span>{option.company}</span>
                </li>
              )}
              onChange={(event, newValue) => {
                handleSelectCustomer(newValue);
              }}
              value={selectedCustomer || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
            />
          </Grid>

          {/* Location Field */}
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Location *
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                fullWidth
                required
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>

          {/* Vehicle Field */}
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Vehicle Registration *
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={vehicleRegistrationNo}
                onChange={(e) => setVehicleRegistrationNo(e.target.value)}
                fullWidth
                required
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>

          {/* Work Description Field */}
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Work Description
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={workDescription}
                onChange={(e) => setWorkDescription(e.target.value)}
                fullWidth
                required
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>

          {/* Customer Comment Field */}
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Customer Comment
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={customerComment}
                onChange={(e) => setCustomerComment(e.target.value)}
                fullWidth
                required
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>

          {/* Fitter Comment Field */}
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Fitter Comment
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={fitterComment}
                onChange={(e) => setFitterComment(e.target.value)}
                fullWidth
                required
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>

          {/* Mileage Fields */}
          <Grid item xs={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "10px", color: "gray", mb: 1 }}
              >
                Mileage
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderColor: "black",
                  borderRadius: "0px",
                  fontSize: "12px",
                  flexGrow: 1,
                }}
                value={mileage}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setMileage(Number(e.target.value))
                } // Convert to number
                fullWidth
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  sx: { height: "40px", fontSize: "12px" },
                }}
                InputProps={{ sx: { height: "40px" } }}
              />
            </Box>
          </Grid>
          {/* Cancel and Save Buttons */}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button variant="outlined" color="error" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ backgroundColor: "green", color: "white" }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "5px",
          height: "100%",
          backgroundColor: borderColor,
        }}
      />
    </Card>
  );
}
