import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import { Page404, Dashboard, Shipments, LoginPage, Admin } from "./elements";
import SalesOrders from "../pages/SalesOrders";
import Transfers from "../pages/Transfers";
import Notifications from "../pages/Notifications";
import Breakdown from "../pages/SalesOrders/Breakdown";
import NewLine from "../pages/SalesOrders/NewLine";
import NewOrder from "src/pages/SalesOrders/NewOrder";
import ShipmentDetails from "src/components/shipment/ShipmentDetails";
import Diagnostics from "../pages/Diagnostics";

// ----------------------------------------------------------------------

export default function Router() {
  // @ts-ignore
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/salesorders", element: <SalesOrders /> },
        { path: "/shipments", element: <Shipments /> },
        { path: "/shipments/:id", element: <ShipmentDetails /> },
        { path: "/transfers", element: <Transfers /> },
        { path: "/notifications", element: <Notifications /> },
        { path: "/salesorders/:id", element: <Breakdown /> },
        { path: "/salesorders/newline", element: <NewLine /> },
        { path: "/salesorders/neworder", element: <NewOrder /> },
        { path: "/admin", element: <Admin /> },
        { path: "/diagnostics", element: <Diagnostics /> },
      ],
    },
    {
      element: (
        <AuthGuard>
          <CompactLayout />
        </AuthGuard>
      ),
      children: [
        { path: "404", element: <Page404 /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/salesorders", element: <SalesOrders /> },
        { path: "/shipments", element: <Shipments /> },
        { path: "/transfers", element: <Transfers /> },
        { path: "/notifications", element: <Notifications /> },
        { path: "/salesorders/:id", element: <Breakdown /> },
        { path: "salesorders/newline", element: <NewLine /> },
        { path: "/salesorders/neworder", element: <NewOrder /> },
        { path: "/admin", element: <Admin /> },
      ],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
