import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import {
  Dashboard,
  ListAlt,
  TransferWithinAStation,
  LocalShipping,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import {PATH_DASHBOARD} from "../../routes/paths";

export default function BottomMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);

    useEffect(() => {
        if (location.pathname.startsWith('/salesorders')) {
            setValue(1);
        } else {
            switch (location.pathname) {
                case '/dashboard':
                    setValue(0);
                    break;
                case '/shipments':
                    setValue(2);
                    break;
                case '/transfers':
                    setValue(3);
                    break;
                
                default:
                    setValue(0);
                    break;
            }
        }
    }, [location.pathname]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                navigate('/dashboard');
                break;
            case 1:
                navigate('/salesorders');
                break;
            case 2:
                navigate('/shipments');
                break;
            case 3:
                navigate('/transfers');
                break;
            default:
                break;
        }
    };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
        color: "black",
      }}
    >
      <BottomNavigationAction
        label="Dashboard"
        icon={<Dashboard />}
        sx={{
          color: value === 0 ? "red" : "black",
          "&.Mui-selected": {
            color: "red",
          },
        }}
      />
      <BottomNavigationAction
        label="Sales Orders"
        icon={<ListAlt />}
        sx={{
          color: value === 1 ? "red" : "black",
          "&.Mui-selected": {
            color: "red",
          },
        }}
      />
        <BottomNavigationAction
        label="Shipments"
        icon={<LocalShipping />}
        sx={{
            color: value === 2 ? "red" : "black",
            "&.Mui-selected": {
                color: "red",
            },
        }}
    />
      <BottomNavigationAction
        label="Transfers"
        icon={<TransferWithinAStation />}
        sx={{
          color: value === 3 ? "red" : "black",
          "&.Mui-selected": {
            color: "red",
          },
        }}
      />
      
    </BottomNavigation>
  );
}
