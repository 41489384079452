// @mui
import { Stack, Typography, Button } from "@mui/material";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// layouts
import LoginLayout from "../../layouts/login";
import {useEffect} from "react";
import {AD_API, VERSION} from "../../config-global";

// ----------------------------------------------------------------------


export default function LoginAD() {
  // NOTE: Turn off comments below to enable Auth0.
  const { login } = useAuthContext();

  const handleLoginAD = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

    const handleRefreshApp = () => {
        const url = new URL(window.location.href);
        // Add cache-busting query parameter to force reload
        url.searchParams.set("cache-bust", Date.now().toString());
        window.location.href = url.toString(); 
    };

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h4">Sign in with your MTS account</Typography>

        {/* <Tooltip title={method} placement="left">
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}
          />
        </Tooltip> */}
      </Stack>

      <Button
        fullWidth
        color="inherit"
        size="large"
        variant="contained"
        onClick={handleLoginAD}
        sx={{
          bgcolor: "text.secondary",
          color: (theme) =>
            theme.palette.mode === "light" ? "common.white" : "grey.800",
          "&:hover": {
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
          },
        }}
      >
        Login
      </Button>
        <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
            V: {VERSION}
        </Typography>
        <Button
            
            color="inherit"
            variant="contained"
            onClick={handleRefreshApp}
            sx={{
                bgcolor: "white",
                color:"grey.800",
                "&:hover": {
                    bgcolor: "text.primary",
                    color: (theme) =>
                        theme.palette.mode === "light" ? "common.white" : "grey.800",
                },
                border: "1px solid",
                
            }}
        >
            Reload Application
        </Button>
        <Typography variant="body2" sx={{ mt: 10, textAlign: "center", fontSize: "8px" }}>
            BASE: {AD_API.redirectUri}
        </Typography>
    </LoginLayout>
  );
}
