import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  Grid,
  Card,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { IBreakdownCardProps } from "../../@types/breakdown";
import { useSwipeable } from "react-swipeable";
import DeleteIcon from "@mui/icons-material/Delete";
import SalesOrdersService from "../../services/SalesOrdersService";
import { useAuthContext } from "../../auth/useAuthContext";
import { useNavigate } from "react-router-dom";

export default function BreakdownCard({ breakdown }: IBreakdownCardProps) {
  const [showDelete, setShowDelete] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [assignedToMe, setAssignedToMe] = useState(false);
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const me = user?.surname + " " + user?.givenName;
    if (breakdown?.fitter === me) {
      setAssignedToMe(true);
    }
  }, [breakdown, user]);
  const handleGoToBreakdown = () => {
    window.location.href = `/salesorders/${breakdown?.id}`;
  };

  const handleDelete = async () => {
    try {
      if (breakdown) {
        await SalesOrdersService.deleteBreakdown(breakdown.id);
        navigate("/sales›orders");
      }
    } catch (error) {
      console.error("Error deleting breakdown:", error);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      console.log("Swiped left");
      if (assignedToMe) {
        setShowDelete(true);
      }
    },
    onSwipedRight: () => {
      console.log("Swiped right");
      setShowDelete(false);
    },
    //preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const borderColor = breakdown?.company === "TAB" ? "green" : breakdown?.company === "MTS" ? "black" : "blue";

  return (
    <div {...handlers}>
      <Card
        sx={{
          mt: 1,
          borderRadius: "10px",
          border: "1px solid",
          borderColor: borderColor,
          cursor: "pointer",
          position: "relative",
        }}
        onClick={handleGoToBreakdown}
      >
        <CardContent>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography
              variant="body2"
              sx={{
                fontSize: "10px",
                color: borderColor,
              }}
            >
              {breakdown?.id} - {breakdown?.no}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography
              variant="body2"
              sx={{
                fontSize: "10px",
                color: borderColor,
              }}
            >
              {breakdown?.company}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {/* Customer Row */}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray", marginRight: 1 }}
                >
                  Customer:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {breakdown?.selltoCustomerName || "N/A"}
                </Typography>
              </Box>
            </Grid>

            {/* Location Row */}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray", marginRight: 1 }}
                >
                  Location:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {breakdown?.location || "N/A"}
                </Typography>
              </Box>
            </Grid>

            {/* Location Code Row */}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray", marginRight: 1 }}
                >
                  Location Code:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {breakdown?.locationCode || "N/A"}
                </Typography>
              </Box>
            </Grid>

            {/* Fitter Row */}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray", marginRight: 1 }}
                >
                  Fitter:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", fontWeight: "600", color: "black" }}
                >
                  {breakdown?.fitter || "N/A"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        {assignedToMe && showDelete && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "50px",
              height: "100%",
              backgroundColor: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setOpenDeleteDialog(true);
              }}
              sx={{ color: "white" }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "5px",
            height: "100%",
            backgroundColor: borderColor,
          }}
        />
      </Card>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this breakdown?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            sx={{ color: "black" }}
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} sx={{ color: "red" }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
