import { useTitleContext } from "../../contexts/TitleContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import NewItemForm from "../../components/SalesOrders/NewItemForm";

export default function NewLine() {
  const location = useLocation();
  const { breakdown } = location.state || {};
  console.log("NewLine.tsx breakdown", breakdown);

  const { setTitle } = useTitleContext();

  const [progressVisible, setProgressVisible] = useState(false);

  useEffect(() => {
    setProgressVisible(true);
    setTitle("New Line");
    setProgressVisible(false);
  }, [setTitle]);

  // const handleBack = () => {
  //     navigate(-1);
  // };

  return (
    <>
      {progressVisible && <LinearProgress />}
      <NewItemForm breakdown={breakdown} />
    </>
  );
}
