import http from "axios";
import { IShipment } from "../@types/shipment";
import axios from "axios";

const GetShipments = async (): Promise<Array<IShipment>> => {
  try {
    const url = `/shipments`;
    const { data } = await http.get(url);

    return ensureArray(data);
  } catch (error) {
    console.error(error);
    return null as never;
  }
};

const GetPermissions = async (): Promise<{ mts: boolean; tab: boolean }> => {
  try {
    const url = `/shipments/permissions`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error getting permissions:", error);
    return { mts: false, tab: false };
  }
};

function ensureArray(data: any): any[] {
  if (Array.isArray(data)) {
    return data;
  } else if (typeof data === "string") {
    return [];
  } else {
    return data;
  }
}
const GetShipment = async (id: number): Promise<IShipment> => {
  try {
    const url = `/shipments/${id}`;
    const { data } = await http.get(url);

    return data;
  } catch (error) {
    console.error(error);
    return null as never;
  }
};

const DeleteShipment = async (id: number): Promise<void> => {
  try {
    const url = `/shipments/${id}`;
    await http.delete(url);
  } catch (error) {
    console.error("Error deleting shipment:", error);
  }
};

const FinishPicking = async (id: number): Promise<void> => {
  try {
    const url = `/shipments/${id}/picked`;
    await http.post(url);
  } catch (error) {
    console.error("Error finishing picking:", error);
    return error;
  }
};

const DeliverShipment = async (id: number, customer: string, signature: string): Promise<void> => {
  try {
    const url = `/shipments/${id}/complete/${customer}?signatureFile=${signature}`;
    await http.post(url);
  } catch (error) {
    console.error("Error delivering shipment:", error);
    return error;
  }
};

const ShipmentsService = {
  GetShipments,
  GetShipment,
  GetPermissions,
  DeleteShipment,
  FinishPicking,
  DeliverShipment,
};

export default ShipmentsService;
