// routes
import { PATH_DASHBOARD } from "./routes/paths";

export const VERSION = "1.0.18";
// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || "9651327bbadb482bbab5a952c5c44313";
export const HOST_API = process.env.REACT_APP_HOST_API || "https://midlandtyreserviceapi.azure-api.net/fitterglobaldev";

export const SIGNALR_NEGOTIATE_URL = process.env.REACT_SIGNALR_NEGOTIATE_URL || "https://midlandtyreserviceapi.azure-api.net/fitterglobaldev/negotiate"; //|| "https://fitterglobalbackend-apim.azure-api.net/fitterglobal/negotiate"; //
// || "https://midlandtyreserviceapi.azure-api.net/fitterglobal/negotiate";// || "http://192.168.0.138:7167/api/negotiate"; //  || "https://midlandtyreserviceapi.azure-api.net/fitterglobal/negotiate";

export const AD_API = {
  clientId:
    process.env.REACT_APP_AD_CLIENT_ID ||
    "05323c24-fb4a-4271-82af-8dfe04628d3b",
  authority:
    process.env.REACT_APP_AD_TENANT_ID ||
    "8cfc2526-6716-4e39-8c24-d6669681dc95",
  scopes: process.env.REACT_APP_AD_PROTECTED_SCOPES
    ? process.env.REACT_APP_AD_PROTECTED_SCOPES.split(",")
    : ["api://05323c24-fb4a-4271-82af-8dfe04628d3b/FitterApp"],
  redirectUri:
    //
   "https://app-dev.midlandtyres.ie/dashboard"
    //, 
  // "https://app.midlandtyres.ie/dashboard"
  // ,
  //   "http://localhost:3000/dashboard"
  //,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root;

export const REACT_SIGNALR_NEGOTIATE_URL =
  process.env.REACT_APP_SIGNALR_NEGOTIATE_URL ||
  "https://midlandtyreserviceapi.azure-api.net/FitterGlobal/negotiate";
//"http://192.168.0.138/api/negotiate";

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 65,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const BUTTONBAR = {
  H_MOBILE_LEFT: 0,
  H_DESKTOP_LEFT: 86,
  H_MOBILE_RIGHT: 0,
  H_TABLET_LEFT: 10,
};

export const NAV = {
  W_BASE: 260,
  W_LARGE: 320,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
