import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { PATH_AUTH } from "../../../routes/paths";
import { useAuthContext } from "../../../auth/useAuthContext";
import { CustomAvatar } from "../../../components/custom-avatar";
import { useSnackbar } from "../../../components/snackbar";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
import {VERSION} from "../../../config-global";

const OPTIONS = [

  {
    label: "Refresh App",
    linkTo: "/refresh",
  },
  {
    label: "Permissions",
    linkTo: "/admin",
  },
];

export default function AccountPopover() {
  const navigate = useNavigate();
  const { user, logout } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    } finally {
      setIsLoggingOut(false);
    }
  };

  const handleRefreshApp = () => {
    const url = new URL(window.location.href);
    // Add cache-busting query parameter to force reload
    url.searchParams.set("cache-bust", Date.now().toString());
    window.location.href = url.toString(); // Force page reload
    // Optionally reset React state here if needed
    // Example: reset your app state with a global state manager (e.g., Redux, Context)
    // myContext.resetState();
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    if (path === "/refresh") {
      handleRefreshApp(); // Perform cache-busting reload
    } else {
      navigate(path); // Navigate to other routes
    }
  };

  return (
      <>
        <IconButtonAnimate
            onClick={handleOpenPopover}
            sx={{
              p: 0,
              ...(openPopover && {
                "&:before": {
                  zIndex: 1,
                  content: "''",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  position: "absolute",
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }),
            }}
        >
          <CustomAvatar>
            <AccountCircleIcon fontSize="large" /> 
          </CustomAvatar>
          <Typography variant="subtitle2" noWrap>
            {user?.givenName} 
          </Typography>
        </IconButtonAnimate>

        <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
          <Box sx={{ my:1.5, px: 2.5 }}>
            <Typography variant="body2" noWrap>
              {user?.givenName} {user?.surname}
            </Typography>
          </Box>
          <Divider sx={{ borderStyle: "dashed" }} />

          <Stack sx={{ p: 1 }}>
            {OPTIONS.map((option) => (
                <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                  {option.label}
                </MenuItem>
            ))}
          </Stack>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Typography variant="subtitle1" sx={{ px: 2.5, py: 2 }}>
            v: {VERSION}
          </Typography>
          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem onClick={handleLogout} disabled={isLoggingOut} sx={{ m: 1 }}>
            {isLoggingOut ? "Logging out..." : "Logout"}
          </MenuItem>
        </MenuPopover>
      </>
  );
}