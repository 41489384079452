import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from "react";

interface TitleContextProps {
  title: string;
  setTitle: (title: string) => void;
}

interface TitleProviderProps {
  children: ReactNode;
}

const TitleContext = createContext<TitleContextProps | undefined>(undefined);

export const TitleProvider: React.FC<TitleProviderProps> = ({ children }) => {
  const [title, setTitle] = useState("Fitter-App");
  const value = useMemo(() => ({ title, setTitle }), [title, setTitle]);

  return (
    <TitleContext.Provider value={value}>{children}</TitleContext.Provider>
  );
};

export const useTitleContext = () => {
  const context = useContext(TitleContext);
  if (!context) {
    throw new Error("useTitleContext must be used within a TitleProvider");
  }
  return context;
};
