import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { useAuthContext } from "src/auth/useAuthContext";

// @mui
import { Box, Container, TextField, Typography } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import diagnosticsService from "src/services/DiagnosticsService";

// components

import { IDiagnostic, IDiagnosticsResult } from "src/@types/diagnostic";
import Diagnostic from "src/components/diagnostic/Diagnostic";
import { useSettingsContext } from "../components/settings";
import Progress from "../components/progress/Progress";

interface PaginationState {
  page: number;
  size: number;
}
// ----------------------------------------------------------------------

export default function Diagnostics() {
  const { themeStretch } = useSettingsContext();
  const { isAuthenticated } = useAuthContext();
  const [diagnosticResult, setDiagnosticResult] =
    useState<IDiagnosticsResult>();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [paginationState, setPaginationState] =
    useState<PaginationState | null>(null);
  const [progressVisible, setProgressVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const dataFiltered = filterData(searchQuery, data);
  const [filteredResults, setFilteredResults] = useState<IDiagnostic[] | null>(
    null
  );

  useEffect(() => {
    setProgressVisible(true);
    if (isAuthenticated) {
      setProgressVisible(true);
      (async function loadDiagnosticData() {
        const response = await diagnosticsService.pagedDiagnostics(0, 25);
        if (response) {
          setDiagnosticResult(response.data);
          setFilteredResults(response.data.data);
        }
        setProgressVisible(false);
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (paginationState) {
      setProgressVisible(true);
      (async function loadDiagnosticData() {
        const response = await diagnosticsService.pagedDiagnostics(
          paginationState.page,
          paginationState.size
        );
        if (response) {
          setDiagnosticResult(response.data);
        }
        setProgressVisible(false);
      })();
    }
  }, [paginationState]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setPaginationState({ page: newPage, size: rowsPerPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    setPage(0);
    setPaginationState({ page: 0, size: newSize });
  };

  const handleSearchKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (searchQuery === "") {
        setFilteredResults(diagnosticResult?.data || null);
        return;
      }
      const filtered = diagnosticResult?.data?.filter(
        (result: any) =>
          result.measurementHeader.vehicleRegistrationNumber === searchQuery
      );
      if (!filtered || filtered.length === 0) {
        await diagnosticsService.getDiagnostic(searchQuery).then((response) => {
          if (response) {
            setFilteredResults([response.data]);
          }
        });
      } else {
        setFilteredResults(filtered || null);
      }
    }
  };

  return (
    <>
      <Progress visible={progressVisible} />

      <Helmet>
        <title> Diagnostics | Fitter Global</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Box component="summary">
          <Typography variant="h6" component="h3" paragraph>
            Diagnostics
          </Typography>

          <TextField
            required
            id="outlined-required"
            label="Search Vehicle Registration Number"
            style={{ minWidth: 300 }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleSearchKeyDown}
          />

          {filteredResults?.map((diagnostic) => (
            <Diagnostic
              key={diagnostic.measurementHeader.vehicleRegistrationNumber}
              isExpanded={false}
              diagnosticProp={diagnostic}
            />
          ))}
          {diagnosticResult !== undefined && diagnosticResult.count > 10 ? (
            <TablePagination
              component="div"
              count={diagnosticResult?.count}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              onPageChange={handlePageChange}
            />
          ) : (
            <> </>
          )}
        </Box>
      </Container>
    </>
  );
}
