import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));

export const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
export const Shipments = Loadable(lazy(() => import("../pages/Shipments")));
export const SalesOrders = Loadable(lazy(() => import("../pages/SalesOrders")));
export const Notifications = Loadable(
  lazy(() => import("../pages/Notifications"))
);
export const Transfers = Loadable(lazy(() => import("../pages/Transfers")));

export const Admin = Loadable(lazy(() => import("../pages/Admin")));

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
