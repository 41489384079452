import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IShipment, IShipmentDetailsObject } from "../../@types/shipment";
import Progress from "../progress/Progress";
import { useTitleContext } from "../../contexts/TitleContext";
import { useNavigate } from "react-router-dom";
import { BUTTONBAR } from "../../config-global";
import ShipmentsService from "../../services/ShipmentsService";
import ShipmentDetail from "./ShipmentDetail";
import { useCallback } from "react";
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import SignalRService from "../../services/SignalRService";
import {NotificationItemProps} from "../../@types/notifications";
import {useSnackbar} from "notistack";
import {useAuthContext} from "../../auth/useAuthContext";

export default function ShipmentDetails() {
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const { setTitle } = useTitleContext();
  //setTitle('Shipment Details');

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const { user } = useAuthContext();
  
  const [progressVisible, setProgressVisible] = useState(false);
  const [workShipment, setWorkShipment] = useState<IShipment | null>(null);
  const [details, setDetailsObjects] =
    useState<Array<IShipmentDetailsObject> | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState<
      Array<NotificationItemProps>
  >([]);

  const [connection, setConnection] = useState<HubConnection | null>(null);


  useEffect(() =>{

    function bindNewMessage(connection: HubConnection) {
      connection.on("newMessage", (message: NotificationItemProps) => {

        enqueueSnackbar(message.Title, {variant: "info"});
        console.log("newMessage", message);

        setNotifications((prevNotifications) => {
          const updatedNotifications = [message, ...prevNotifications];
          console.log("notifications", updatedNotifications);
          return updatedNotifications;
        });
      });
    }

    function bindUserMessage(connection: HubConnection) {
      connection.on("userMessage", (message: NotificationItemProps) => {

        enqueueSnackbar(message.Title, {variant: "info"});
        console.log("userMessage", message);

        setNotifications((prevNotifications) => {
          const updatedNotifications = [message, ...prevNotifications];
          console.log("notifications", updatedNotifications);
          return updatedNotifications;
        });
      });
    }

    function bindSalesOrder(connection: HubConnection) {
      connection.on("salesOrder", (message: NotificationItemProps) => {

        enqueueSnackbar(message.Title, {variant: "info"});
        console.log("salesOrder", message);

        setNotifications((prevNotifications) => {
          const updatedNotifications = [message, ...prevNotifications];
          console.log("notifications", updatedNotifications);
          return updatedNotifications;
        });
      });
    }
    function bindPayment(connection: HubConnection) {
      connection.on("payment", (message: NotificationItemProps) => {
        enqueueSnackbar(message.Title, {variant: "success"});
        console.log("payment", message);

        setNotifications((prevNotifications) => {
          const updatedNotifications = [message, ...prevNotifications];
          console.log("notifications", updatedNotifications);
          return updatedNotifications;
        });
      });
    }

    function bindError(connection: HubConnection) {
      connection.on("error", (message: NotificationItemProps) => {
        enqueueSnackbar(message.Title, {variant: "error"});
        alert(message.Title);
        console.log("error", message);

        setNotifications((prevNotifications) => {
          const updatedNotifications = [message, ...prevNotifications];
          console.log("notifications", updatedNotifications);
          return updatedNotifications;
        });
      });
    }
    async function connectSignalR() {
      const me = user?.surname + " " + user?.givenName;
      const fitterId = `${me}`.replace(/\s+/g, '_').toLowerCase();
      const userName = fitterId;
      const signalrData = await SignalRService.negotiate(userName);
      if (signalrData) {
        const url = `${signalrData.data.url}&userId=${userName}`;
        const newConnection = new HubConnectionBuilder()
            .withUrl(url, {
              accessTokenFactory: () => signalrData.data.accessToken,
            })
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
        newConnection
            .start()
            .then(() => {
              bindPayment(newConnection);
              bindSalesOrder(newConnection);
              bindUserMessage(newConnection);
              bindNewMessage(newConnection);
              bindError(newConnection);

            })
            .catch((error) => {
              console.error(error.message);
            });
      }
    }

    connectSignalR().then();

  }, [user, enqueueSnackbar]);
  const handleBack = () => {
    navigate("/shipments");
  };

  const getDetailedObjects = (
    shipment: IShipment
  ): Array<IShipmentDetailsObject> => {
    const shipmentDetailsMap: Record<string, IShipmentDetailsObject> = {};
    console.log("shipment =>", shipment);
    shipment.details.forEach((detail) => {
      const clientKey = `${detail.customerNo}`;
      console.log("clientKey =>", clientKey);
      if (!shipmentDetailsMap[clientKey]) {
        shipmentDetailsMap[clientKey] = {
          id: shipment.header.id,
          status: shipment.header.status,
          fitter: shipment.header.fitter,
          company: shipment.header.company,
          clientNo: detail.customerNo,
          clientName: detail.customerName,
          lines: [],
        };
      }
      shipmentDetailsMap[clientKey].lines.push(detail);
    });
    return Object.values(shipmentDetailsMap);
  };

  const reload = useCallback(async () => {
    if (!id) return;
    setProgressVisible(true);
    const response = await ShipmentsService.GetShipment(Number(id));
    console.log("response =>", response);
    if (response) {
      setWorkShipment(response);
      const detailedObjects: Array<IShipmentDetailsObject> =
        getDetailedObjects(response);
      setDetailsObjects(detailedObjects);
    }
    setProgressVisible(false);
  }, [id, setProgressVisible, setWorkShipment, setDetailsObjects]);

  const handleFinishPacking = async () => {
    if (!id) return;
    setProgressVisible(true);
    await ShipmentsService.FinishPicking(Number(id));
    setProgressVisible(false);
  };

  useEffect(() => {
    setTitle("Shipment Details");
    if (id) {
      reload();
    }
  }, [id, setTitle, reload]);

  return (
    <>
      <Progress visible={progressVisible} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: "fixed",
          top: 70,
          // left: 87,
          width: "100%",
          height: 56,
          backgroundColor: "white",
          // zIndex: 1000,
          padding: "16px 16px",
          boxShadow: "0px 4px 2px -2px gray",
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconButton onClick={handleBack} sx={{ color: "black" }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="body2" sx={{ color: "black", fontSize: "9px" }}>
            Back
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconButton onClick={reload} sx={{ color: "black" }}>
            <RefreshIcon />
          </IconButton>
          <Typography variant="body2" sx={{ color: "black", fontSize: "9px" }}>
            Reload
          </Typography>
        </Box>
        {workShipment?.header.status === "ReadyToPick" && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton onClick={handleFinishPacking} sx={{ color: "green" }}>
              <CheckCircle />
            </IconButton>
            <Typography
              variant="body2"
              sx={{ color: "black", fontSize: "9px" }}
            >
              Finish Picking
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{mt:8, zIndex:20000}}>

      {details && (
            details.map((detail, index) => (
                <ShipmentDetail key={index} shipmentObject={detail} />
            ))
      )}
      </Box>

    </>
  );
}
